import { render, staticRenderFns } from "./SaveReportSuccessDialog.vue?vue&type=template&id=1f23cdff&scoped=true"
import script from "./SaveReportSuccessDialog.vue?vue&type=script&lang=js"
export * from "./SaveReportSuccessDialog.vue?vue&type=script&lang=js"
import style0 from "./SaveReportSuccessDialog.vue?vue&type=style&index=0&id=1f23cdff&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f23cdff",
  null
  
)

export default component.exports