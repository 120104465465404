<template>
  <div class="page-container">
    <!-- Modal Placeholder -->
    <SaveReportModal
      v-show="shouldDisplaySaveReportModal"
      @close="onSaveReportModalClosed($event)"
    ></SaveReportModal>

    <SaveReportSuccessDialog
      v-show="shouldDisplaySaveReportSuccessDialog"
      @close="onSaveReportSuccessDialogClosed"
    ></SaveReportSuccessDialog>

    <!-- Page Content -->
    <section class="page-header">
      <div>
        <!-- <el-button>離開</el-button> -->
      </div>
      <div class="page-header__nav">
        <div class="page-header__nav-item">
          1 問答
        </div>
        <div class="page-header__nav-item">
          2 分析
        </div>
        <div class="page-header__nav-item page-header__nav-item--highlighted">
          3 結果
        </div>
      </div>
    </section>
    <section class="result-section">
      <div class="result-section__card">
        <div class="result-section__card-inner-container">
          <span
            v-for="(score, index) in detectedScores"
            v-bind:key="index"
            class="result-section__score-label"
            v-bind:class="{
              'result-section__score-label--active':
                selectedScore.nameZh === score.nameZh
            }"
            @click="selectedScore = score"
            >{{ score.nameZh }}
          </span>
        </div>
        <div class="result-section__score-description">
          {{ selectedScore.descriptionZh }}
        </div>
        <div>
          <el-button
            class="full-width-btn"
            type="success"
            @click="onSaveBtnClicked"
          >
            保存記錄
          </el-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SaveReportModal from "@/components/SaveReportModal.vue";
import SaveReportSuccessDialog from "@/components/SaveReportSuccessDialog.vue";

export default {
  components: {
    SaveReportModal,
    SaveReportSuccessDialog
  },
  data() {
    return {
      shouldDisplaySaveReportModal: false,
      shouldDisplaySaveReportSuccessDialog: false,
      detectedScores: [],
      selectedScore: {}
    };
  },
  methods: {
    onSaveBtnClicked() {
      this.shouldDisplaySaveReportModal = true;
    },
    onSaveReportModalClosed(event) {
      this.shouldDisplaySaveReportModal = false;
      if (event === "SUCCESS") {
        this.shouldDisplaySaveReportSuccessDialog = true;
      }
    },
    onSaveReportSuccessDialogClosed() {
      this.shouldDisplaySaveReportSuccessDialog = false;
    }
  },
  mounted() {
    this.detectedScores = this.$store.getters.detectedScores;
    this.selectedScore = this.detectedScores[0];
  }
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100vh;
  background: linear-gradient(#6146df, #1a9dce);
  padding: 25px 25px;
}

.page-header {
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;

  &__nav {
    display: flex;
    justify-content: flex-end;
  }

  &__nav-item {
    opacity: 0.3;
    margin: 0px 10px;
    font-weight: 400;
  }

  &__nav-item--highlighted {
    opacity: 1;
  }
}

.result-section {
  display: flex;
  justify-content: center;

  &__card {
    margin: 50px 0px;
    border-radius: 8px;
    background-color: #fff;
    // width: 80%;
    color: #414c64;
    padding: 30px;
  }

  &__score-label {
    cursor: pointer;
  }

  &__score-label--active {
    font-size: 20px;
    font-weight: bold;
  }

  &__score-description {
    margin: 20px 0px;
  }
}
</style>
