<template>
  <div class="modal-backdrop" @click="emitCloseEvent">
    <div class="modal-body" role="dialog" @click.stop>
      <section class="description">保存記錄</section>
      <section class="info-form">
        <div class="info-form__label">
          你尚未保存分析結果，建議你先點選"保存數據“然後才離開，否則所分析的結果將會消失。
        </div>
        <el-form :model="form" :rules="formRules" ref="form">
          <el-form-item prop="organization">
            <el-select v-model="form.organization" placeholder="團體">
              <el-option
                label="香港聖公會麥理浩夫人中心"
                value="SKH"
              ></el-option>
              <el-option label="其他" value="OTHERS"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="identity">
            <el-input
              v-model="form.identity"
              placeholder="輸入個案編號"
              prefix-icon="el-icon-collection"
            ></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              v-model="form.email"
              placeholder="輸入聯絡電郵"
              prefix-icon="el-icon-message"
            ></el-input>
          </el-form-item>
          <el-form-item prop="lastName">
            <el-input
              v-model="form.lastName"
              placeholder="輸入姓氏"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="firstName">
            <el-input
              v-model="form.firstName"
              placeholder="輸入名字"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="age">
            <el-select v-model="form.age" placeholder="年齡">
              <el-option label="5-10" value="5-10"></el-option>
              <el-option label="10-15" value="10-15"></el-option>
              <el-option label="15-20" value="15-20"></el-option>
              <el-option label="20-25" value="20-25"></el-option>
              <el-option label="25-30" value="25-30"></el-option>
              <el-option label="30-35" value="30-35"></el-option>
              <el-option label="35-40" value="35-40"></el-option>
              <el-option label="40-45" value="40-45"></el-option>
              <el-option label="45-50" value="45-50"></el-option>
              <el-option label="50-55" value="50-55"></el-option>
              <el-option label="55-60" value="55-60"></el-option>
              <el-option label="60-65" value="60-65"></el-option>
              <el-option label="65-70" value="65-70"></el-option>
              <el-option label="70-75" value="70-75"></el-option>
              <el-option label="75-80" value="75-80"></el-option>
              <el-option label="80-85" value="80-85"></el-option>
              <el-option label="85-90" value="85-90"></el-option>
              <el-option label="90-95" value="90-95"></el-option>
              <el-option label="95-100" value="95-100"></el-option>
              <el-option label="100+" value="100+"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="gender">
            <el-select v-model="form.gender" placeholder="性別">
              <el-option label="男性" value="MALE"></el-option>
              <el-option label="女性" value="FEMALE"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </section>
      <section>
        <el-button
          class="full-width-btn"
          type="success"
          @click="onSendBtnClicked"
          >發送
        </el-button>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
}

.modal-body {
  height: 600px;
  width: 300px;
  border-radius: 8px;
  padding: 15px;
  margin: 15px;

  background-color: #fff;
  color: #000;
}

.description {
  font-size: 30px;
}

.info-form {
  &__label {
    font-size: 13px;
    margin: 15px 0px 25px 0px;
  }

  margin-bottom: 25px;
}
</style>

<script>
export default {
  name: "SaveReportModal",
  data() {
    return {
      form: {
        organization: "",
        identity: "",
        email: "",
        firstName: "",
        lastName: "",
        age: "",
        gender: ""
      },
      formRules: {
        organization: [
          { required: false, message: "必須填寫", trigger: "blur" }
        ],
        identity: [{ required: true, message: "必須填寫", trigger: "blur" }],
        email: [{ required: true, message: "必須填寫", trigger: "blur" }],
        firstName: [{ required: true, message: "必須填寫", trigger: "blur" }],
        lastName: [{ required: true, message: "必須填寫", trigger: "blur" }],
        age: [{ required: true, message: "必須填寫", trigger: "blur" }],
        gender: [{ required: true, message: "必須填寫", trigger: "blur" }]
      }
    };
  },
  methods: {
    onSendBtnClicked() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$store.dispatch("saveUserProfile", this.form).then(() => {
            this.$emit("close", "SUCCESS");
          });
        }
      });
    },
    emitCloseEvent() {
      this.$emit("close");
    }
  }
};
</script>
