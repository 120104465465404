<template>
  <div class="modal-backdrop" @click="emitCloseEvent">
    <div class="modal-body" role="dialog" @click.stop>
      <section class="description">保存記錄</section>
      <section class="info-form">
        <div class="info-form__label">
          <p>
            記錄已經成功發送至你的電子郵箱
          </p>
          <!-- <div v-if="!hasLoggedIn">
            想方便仔細掌握自己每日的體質變化嗎？
          </div>
          <div v-if="!hasLoggedIn">
            請即登記成為平台用戶。
          </div> -->
        </div>
      </section>
      <section>
        <!-- <el-button
          v-if="!hasLoggedIn"
          class="full-width-btn"
          type="success"
          @click="onSignupBtnClicked"
          >免費登記成為用戶
        </el-button> -->
        <el-button
          class="full-width-btn"
          type="success"
          @click="onHomeBtnClicked"
          >返回主頁面
        </el-button>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
}

.modal-body {
  height: 150px;
  width: 300px;
  border-radius: 8px;
  margin: 15px;
  padding: 15px;

  background-color: #fff;
  color: #000;
}

.description {
  font-size: 30px;
}

.info-form {
  &__label {
    font-size: 13px;
    margin: 15px 0px 25px 0px;
  }

  margin-bottom: 25px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SaveReportSuccessDialog",
  data() {
    return {};
  },
  methods: {
    onSignupBtnClicked() {
      this.$router.push("signup");
    },
    onHomeBtnClicked() {
      this.$router.push("/");
    },
    emitCloseEvent() {
      this.$emit("close");
    }
  },
  computed: {
    ...mapGetters(["hasLoggedIn"])
  }
};
</script>
